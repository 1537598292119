// /components/styled/MyWorkouts.js

import React, { useEffect, useRef, useState } from 'react';
import packageJson from '../../../package.json';

import MobileLoadingDivLayer  from './MobileLoadingDivLayer';
import { RedSpan, Icon, IconTwo, MobileVersionDiv } from './title';
import { iconSrc } from '../../constants';

const MyWorkouts = ({
  loaded, workouts, onWorkoutClick
}) => {
  const count = workouts.length;
  const s_string = count > 1 ? "s" : ""; 
  const workouts_string = count === 0 ? "You currently have no Assigned Workouts:" :
  "You currently have " + count + " Assigned Workout" + s_string + ":";
  const nameFontSize = "4vw";
  const subtitleFontSize = "3vw";

  let rowIndex = 0;

  console.log('~~~~~ MyWorkouts(), workouts count is ' + count + ', and loaded is ' + loaded);

  return (
    <div>
      {loaded ? '' : <MobileLoadingDivLayer />} 
      <div className="SomeWrapper">
        <MobileVersionDiv>v{packageJson.version}</MobileVersionDiv>
        <div className="IconAndTitle" style={{ width: "100vw", display: "flex", flexDirection: "column", justifyContent: "center", paddingBottom: "5vw", paddingTop: "0vw" }}>
          <div className="HeaderIconDiv" style={{ width: "100vw", display: "flex", justifyContent: "center", paddingTop: "3vw", paddingBottom: "3vw" }}>
            <Icon style={{ width: "6vw", height: "6vw", margin: "auto" }} src={iconSrc.workouts_header_black} />
          </div>
          <h4 style={{ width: "100%", fontSize: "5vw", fontWeight: "bold", textAlign: "center" }}>WORKOUTS</h4>
          <div className="Subtitle" style={{ width: "100%", fontSize: "2.5vw", textAlign: "center", color: "#777" }}>
            {workouts_string}
          </div>
        </div>
      </div>
      <br />
      {workouts.map((data) => {
        const id_guid = data.workout_id_guid; // could also have used data.id_guid
        const name = data.name_string;
        console.log('~~~~~ MyWorkouts(), TableBody() - data.name_string is *' + data.name_string + '* and name is *' + name + '*');
        const group_name = data.group_name_string;
        console.log('~~~~~ MyWorkouts(), TableBody() - data.group_name_string is *' + data.group_name_string + '* and group_name is *' + group_name + '*');
        const first_name = data.program_user_first_name_string;
        const last_name = data.program_user_last_name_string;
        const automated = data.assessment_workout_boolean;

        const assigned_by = group_name ? group_name : 
        first_name && last_name ? first_name + ' ' + last_name : 
        automated ? "Physmodo AI" : "unknown";

        rowIndex++;
        console.log('~~~~~ MyWorkouts(), TableBody() - data.id_guid is *' + id_guid + '* and rowIndex is *' + rowIndex + '*');

        return(
          <div 
            data-name={name} 
            id={id_guid + '_' + rowIndex} 
            key={id_guid + '_' + rowIndex}
            style={{ 
              display: loaded ? "flex" : "none", 
              paddingRight: "3vw"
            }}  
            onClick={(e, id_guid) => onWorkoutClick(e, id_guid)}
            title="Tap here to see the details of this Workout"
          >
            <div className="WorkoutMetaDiv" style={{ width: "100%", textAlign: "left", fontSize: "4vw", fontWeight: "300", float: "left", padding: "3vw" }}>
              <Icon style={{ width: "4vw", height: "4vw", paddingBottom: "20px", marginBottom: "20px" }} src={iconSrc.workout} />
              <div style={{ display: "inline-block", paddingTop: "0vw", marginBottom: "-20px" }}>
                <div style={{ display: "inline-block", paddingTop: "0vw", marginBottom: "-20px", fontSize: nameFontSize, paddingLeft: "3vw" }}>
                  <b>{data.name_string}</b>
                </div>
                 
                {/*<span style={{ fontSize: "smaller", opacity: "0.6", paddingLeft: "30px" }}> from {assigned_by}</span>*/}
                <p style={{ fontSize: subtitleFontSize, opacity: "0.6", paddingLeft: "3vw" }}>
                  Assigned By: <b>{assigned_by}</b><br />
                </p>
                {/* Assigned By: <b>{data.group_name_string ? data.group_name_string : data.program_user_first_name_string + ' ' + data.program_user_last_name_string}</b><br /> */}


              </div>
            </div>
            <IconTwo style={{ width: "8vw", height: "8vw", marginTop: "2vw", margingRight: "5vw" }} src={data.user_workout_compliance_boolean ? iconSrc.compliance_checked : iconSrc.compliance_unchecked} />
          </div>
        )
      })}
    </div>
  );
};

export default MyWorkouts;