// WorkoutSection.js

import React, { useState, useRef, useEffect } from 'react';

import styled from "styled-components";
import { iconSrc } from '../../constants';
import { ExploreIcon } from './exploreStuff';

import SectionHeaderTabs from './SectionHeaderTabs';
import WorkoutEdit from './WorkoutEdit'; // WorkoutCreate
import Explore from './Explore';

const workoutTabs = [
  {title: "Workout", icon: iconSrc.workout_light, component: "WorkoutTab" }, 
  {title: "Explore", icon: iconSrc.search, component: "ExploreTab" }
];

const noExploreTab = [
  {title: "", icon: iconSrc.readiness_white, component: "WorkoutTab" } // TODO: add a blank, transparent icon to iconSrc in themes_and_colors
]

const fakeWorkoutResults = {
  group_array: [
    {
      id_guid: "group_1_fake_id",
      name_string: "Group 1.1.1.1",
      group_path_string: ""
    },
    {
      id_guid: "group_2_fake_id",
      name_string: "Group 1.2",
      group_path_string: ""
    }
  ],
  user_array: [
    {
      id_guid: "user_1_fake_guid",
      last_name_string: "LastName",
      first_name_string: "FirstName",
      email_string: "user_1_fake@physmodo.com",
      group_approval_boolean: true,
      user_approval_boolean: true,
      staff_boolean: false,
      last_assessment_score_composite_float: "0.5",
      last_assessment_score_composite_delta_float: "0.5",
      last_assessment_color_string: "green",
      readiness_overall_integer: "5",
      readiness_overall_color_string: "green",
      readiness_sleep_integer: "4",
      readiness_sleep_color_string: "green",
      readiness_hydration_integer: "3",
      readiness_hydration_color_string: "yellow",
      readiness_stress_integer: "2",
      readiness_stress_color_string: "red",
      readiness_soreness_integer: "2",
      readiness_soreness_color_string: "red",
      readiness_injury_integer: "",
      readiness_injury_color_string: "",
      compliance_integer: "",
      compliance_color_string: ""
    },
    {
      id_guid: "user_2_fake_guid",
      last_name_string: "LastName",
      first_name_string: "FirstName",
      email_string: "user_2_fake@physmodo.com",
      group_approval_boolean: true,
      user_approval_boolean: true,
      staff_boolean: false,
      last_assessment_score_composite_float: "0.5",
      last_assessment_score_composite_delta_float: "0.5",
      last_assessment_color_string: "green",
      readiness_overall_integer: "5",
      readiness_overall_color_string: "green",
      readiness_sleep_integer: "4",
      readiness_sleep_color_string: "green",
      readiness_hydration_integer: "3",
      readiness_hydration_color_string: "yellow",
      readiness_stress_integer: "2",
      readiness_stress_color_string: "red",
      readiness_soreness_integer: "2",
      readiness_soreness_color_string: "red",
      readiness_injury_integer: "",
      readiness_injury_color_string: "",
      compliance_integer: "",
      compliance_color_string: ""
    },
    {
      id_guid: "user_3_fake_guid",
      last_name_string: "LastName",
      first_name_string: "FirstName",
      email_string: "user_3_fake@physmodo.com",
      group_approval_boolean: true,
      user_approval_boolean: true,
      staff_boolean: false,
      last_assessment_score_composite_float: "0.5",
      last_assessment_score_composite_delta_float: "0.5",
      last_assessment_color_string: "green",
      readiness_overall_integer: "5",
      readiness_overall_color_string: "green",
      readiness_sleep_integer: "4",
      readiness_sleep_color_string: "green",
      readiness_hydration_integer: "3",
      readiness_hydration_color_string: "yellow",
      readiness_stress_integer: "2",
      readiness_stress_color_string: "red",
      readiness_soreness_integer: "2",
      readiness_soreness_color_string: "red",
      readiness_injury_integer: "",
      readiness_injury_color_string: "",
      compliance_integer: "",
      compliance_color_string: ""
    }
  ]
}

const WorkoutSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const WorkoutTabSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 0;
  padding-top: 40px;
  font-size: larger;
  /* background: green; */
`;

const WorkoutSection = ({
  // do we need any input props here, for WorkoutSection context? a user_id? anything?
  // we might need everything, so that user_l can use the same state as user_r
  // so in Shell(): const[userState, setUserState] = useState({});
  // where userState = { user, assessments, assessment, activeTab, handlers, ...? }
  // and userHandlers = { tabChange, assessmentClick }
  // state, handlers, scopeId, results, onSearch, onAddNewClick, onRowClick, onRowRemoveClick, onRowAddClick, onAddUserClick, onAddGroupClick, isMobile
  isMobile, refr, item, exercises, create, refresh, handlers, results, movement, onSaveClick, onDuplicate, onAddExercise, onVideoClick, onClearMovement, activeTabIndex, onTabChange // refreshWorkouts,
}) => {
  // const mounted = useRef(true);
  const [activeTab, setActiveTab] = useState(activeTabIndex);  // integer index of active tab // TODO: does this need to be here, or in UserSection?
  const [createMode, setCreateMode] = useState(create);
  // const [workoutItem, setWorkoutItem] = useState(item);

  console.log('~~~~~ WorkoutSection(), refresh is ' + refresh + ', item.name_string is ' + item.name_string);

  function handleWorkoutTabChange(index){
    console.log('~~~~~ WorkoutSection(), handleWorkoutTabChange() - new tab index is ' + index.toString());
    // check to make sure Sidebar has finished rendering???
    // - maybe have an onGroupListUpdatCompleted event with the handler up here in Shell,
    // -- and set a state variable like 'sidebarLoaded' that could be used here to enable/disable the automatic setActiveTab?
    // OR ---> just don't have that setAciveTab happen until later, by not having the user_r component in the default set :-)
    setActiveTab(index);
    onTabChange(index);
  }

  function handleSaveClick(...props){ // attempting to switch to edit mode after save of newly created workout, but needs to happen in Shell,
    console.log('~~~~~ WorkoutSection(), handleSaveClick, item.public_boolean is ' + item.public_boolean);
    // if (create === true) {
    //   console.log('~~~~~ WorkoutSection(), handleSaveClick() - saving a new Workout');
    //   createMode = false;
    // }
    onSaveClick(...props);
  }

  function handleDuplicate(newFormItem){
    console.log('~~~~~ WorkoutSection(), handleDuplicate()');
    // first let's change the Header Title above the WorkoutEdit form
    onDuplicate();
    // then we need to update the formItem the WorkoutEdit form will be using
    // setWorkoutItem(newFormItem);
    item = newFormItem;
    // then all we need to do is set createMode to true
    setCreateMode(true);
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  if (item && item.id_guid && item.name_string && item.title_string) {
    console.log('~~~~~ WorkoutSection(), item.id_guid is *' + item.id_guid + '*, and item.name_string is *' + item.name_string + '*, and item.title_string is *' + item.title_string + '*');
  }

  return (
    <WorkoutSectionWrapper >
      <SectionHeaderTabs tabObjects={create ? noExploreTab : workoutTabs} onTabChange={handleWorkoutTabChange} initialTab={refr.includes("_l") ? activeTab : 0} />

      {activeTab === 0 &&
        <WorkoutTabSectionWrapper style={{ padding: "0px", paddingTop: "20px" }}>
          <WorkoutEdit 
            className="workout_create_r"
            refr="workout_create_r" 
            key={"workout_create_r_" + refresh}
            item={item}
            movement={movement} // movementObj to add new Exercise with...
            exercises={exercises} // TODO: do we need this? or is the list of exercises fetched by PickList inside of WorkoutEdit?
            create={createMode}
            onAddExerciseClick={onAddExercise}
            onSaveClick={handleSaveClick}
            onDuplicate={handleDuplicate}
            onVideoClick={onVideoClick}
            clearMovement={onClearMovement}
          /> 
        </WorkoutTabSectionWrapper>
      }
      {activeTab === 1 &&
        <WorkoutTabSectionWrapper style={{ padding: "0px", paddingTop: "20px" }}>
          <Explore 
            // key={"explore_workout_" + refreshWorkouts}
            key={"explore_workout_tab" + refresh}
            className="explore_workout_tab"
            refr="explore_workout_tab"
            scope="workout" 
            scopeId={item.id_guid}
            results={results}
            handlers={handlers}
          />
        </WorkoutTabSectionWrapper>
      }
    </WorkoutSectionWrapper >

  );
}

export default WorkoutSection;
