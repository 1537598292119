// Explore.js

import React, { useState, useEffect } from 'react';
import { iconSrc } from '../../constants';
import { 
  ExploreBarCircleButton,
  ExploreBarOvalButton,
  ExploreBarSearchInput,
  ExploreBarWrapper,
  ExploreHeaderCell,
  ExploreIcon,
  ExploreTableBodyWrapper,
  ExploreTableCell,
  ExploreTableMovementCell,
  ExploreTableHeader,
  ExploreTableRow,
  ExploreTableMovementRow,
  ExploreTableRowMessage,
  ExploreTableWrapper,
  ExploreWrapper
} from './exploreStuff';
import LoadingDivLayer  from './LoadingDivLayer';
import ExploreScoreCircle from './ExploreScoreCircle';

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const ExploreBar = ({
  refr, likeString, scope, scopeId, adding, handlers, handleSearch
}) => {

  const [showGroups, setShowGroups] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [showWorkouts, setShowWorkouts] = useState(scope === 'workouts' ? true : false);
  const [searchTerm, setSearchTerm] = useState(likeString ? likeString : '');
  const [activeFilters, setActiveFilters] = useState([]);

  if (!scope && refr.includes('exercise')) { scope = 'exercise'; }

  const explore_item = scope === "group" || scope === "user" || scope === "workout" || scope === "exercise";
  const explore_user = scope === "user";
  const noFilters = (scope === "exercise");
  // const noFilters = (scope === "workout" || scope === "exercise") && !refr.includes('explore_workout_tab'); // TODO: make filters show for workouts Explore tab
  const noWorkoutFilter = refr.includes('explore_workout_tab') || refr.includes('explore_add_to_workout');

  console.log('~~~~~ Explore(), ExploreBar() - scope: ' + scope + ', scopeId: ' + scopeId + ', explore_item: ' + explore_item + ', noFilters: ' + noFilters);

  function handleFilterClick(filter){
    let newArray = activeFilters.slice(); // NOTE we do not want an assignment here, we want to clone the array
    // console.log('~~~~~ Explore(), handleFilterChange() - activeFilters is ' + activeFilters);
    const filterIndex = newArray.indexOf(filter);
    // console.log('~~~~~ Explore(), handleFilterChange() - newArray is ' + newArray);
    
    if (filterIndex === -1){ //filter was not active
      newArray.push(filter);
      if (filter === 'group') { setShowGroups(true) }
      if (filter === 'user') { setShowUsers(true) }
      if (filter === 'workout') { setShowWorkouts(true) }
    } else {
      // use index to slice out the offending filter
      newArray.splice(filterIndex, 1)
      if (filter === 'group') { setShowGroups(false) }
      if (filter === 'user') { setShowUsers(false) }
      if (filter === 'workout') { setShowWorkouts(false) }
    }
    // console.log('~~~~~ Explore(), handleFilterChange() - after update newArray is ' + newArray);
    setActiveFilters(newArray);
  }

  function onSearchKeyPress(e){
    let key = e.keyCode || e.which;
    console.log('~~~~~ Explore(), ExploreBar(), onSearchKeyPress() - key pressed is ' + key);
    console.log('~~~~~ Explore(), ExploreBar(), onSearchKeyDown() - searchTerm is ' + searchTerm);
    if(key === 13){
      // e.preventDefault(); // Ensure it is only this code that runs

      // alert("Enter was pressed");
      onSearchClick(searchTerm);
    }
  }

  function onSearchClick(like_string){
    console.log('~~~~~ Explore(), ExploreBar(), onSearchClick()');
    //      refr, like_string, group_id, user_id, workout_id, group_boolean, user_boolean, workout_boolean, inverse_boolean
    const group_id = refr.includes('group') ? scopeId : null;
    const user_id = refr.includes('user') ? scopeId : null;
    const workout_id = refr.includes('workout') ? scopeId : null;
    const workoutsOnly = refr.includes('workout') && !refr.includes('add_to') && !refr.includes('_tab') ? true : false;

    console.log('~~~~~~~~~~ Explore(), ExploreBar(), onSearchClick() - activeFilters is ' + activeFilters);
    console.log('~~~~~~~~~~ Explore(), ExploreBar(), onSearchClick() - workoutsOnly is ' + workoutsOnly);

    handleSearch(
      refr,
      like_string,
      group_id, 
      user_id, 
      workout_id, 
      workoutsOnly ? false : showGroups,  // group_boolean
      workoutsOnly ? false : showUsers,   // user_boolean
      showWorkouts,                       // workout_boolean
      adding
    );
  }

  // function yellOut(e){
  //   let targetId = e.currentTarget.id;
  //   let new_char = e.currentTarget.value;
  //   searchTerm += new_char;
  //   console.log('~~~~~ HEY!! ' + targetId + ', value is ' + new_char + ', and like_string is ' + searchTerm);
  // }

  console.log('~~~~~~~~~~~~~ Explore(), ExploreBar() - scope is ' + scope + ', and scopeId is ' + scopeId + ', and explore_item is ' + explore_item + ', and adding is ' + adding);

  return (

    <ExploreBarWrapper>
      <ExploreBarCircleButton id="group_FilterBtn" title="Filter search - include Groups, or not" selected={activeFilters && activeFilters.includes('group')} style={{ marginLeft: "20px", opacity: noFilters ? "0.0" : "1.0" }} onClick={() => handleFilterClick('group')}>
        <ExploreIcon src={iconSrc.group_light} style={{ height: "14px", width: "20px", opacity: "1.0", marginTop: "-5px", marginLeft: "-5px", }} />
      </ExploreBarCircleButton>
      <ExploreBarCircleButton id="user_FilterBtn" title="Filter search - include Users, or not" selected={activeFilters && activeFilters.includes('user')} onClick={() => handleFilterClick('user')} style={{ opacity: noFilters || explore_user ? "0.0" : "1.0" }} >
        <ExploreIcon src={iconSrc.user_light} style={{ height: "18px", width: "18px", opacity: "1.0", paddingLeft: "3px" }} />
      </ExploreBarCircleButton>
      <ExploreBarCircleButton id="workout_FilterBtn" title="Filter search - include Workouts, or not" selected={activeFilters && activeFilters.includes('workout')} onClick={() => handleFilterClick('workout')} style={{ opacity: noFilters || noWorkoutFilter ? "0.0" : "1.0" }}>
        <ExploreIcon src={iconSrc.workout} style={{ height: "20px", width: "20px", opacity: "1.0" }} />
      </ExploreBarCircleButton>
      
      <ExploreBarSearchInput placeholder="Search" type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} onKeyDown={e => onSearchKeyPress(e)} style={{ marginBottom: "-2px"}} id={"search_term_text_input_" + refr} />
      {/* onBlur={(e)=>handleSearch(e.target.value)} */}
      {/* search button always follows immediately to the right of the search text input */}
      {/* next set of two buttons, to the right of search, can be one of three: */}
      {/* - either [blank-space, blank-space] for explore_l */}
      {/* - either [blank-space, +-add-button] for explore_item (group|user|workout|exercise) */}
      {/* - or [add-user, add-group] for explore_group_add */}
      
      {(explore_item && !adding) && 
        <>
          <ExploreBarOvalButton title="Perform the Search" onClick={() => onSearchClick(searchTerm)}>
            <ExploreIcon src={iconSrc.search} style={{ height: "24px", width: "24px", marginBottom: "-5px"}} />
          </ExploreBarOvalButton>
          <ExploreBarOvalButton style={{ paddingBottom: "5px", opacity: "0" }}>
            <ExploreIcon src={iconSrc.plus} style={{ height: "22px", width: "22px", marginRight: "0px" }} />
          </ExploreBarOvalButton>
          <ExploreBarOvalButton title="Add to this List" onClick={() => handlers.onAddNewClick(refr)} style={{ marginRight: "55px", paddingBottom: "5px" }}>
            <ExploreIcon src={iconSrc.plus} style={{ height: "22px", width: "22px", marginRight: "0px"}} />
          </ExploreBarOvalButton> 
        </>
      }
      {(explore_item && adding) &&
        <>
          <ExploreBarOvalButton title="Perform the Search" onClick={() => onSearchClick(searchTerm)}>
            <ExploreIcon src={iconSrc.search} style={{ height: "24px", width: "24px", marginBottom: "-5px"}} />
          </ExploreBarOvalButton>
          <ExploreBarOvalButton title="Invite new Users" onClick={handlers.onAddUserClick} style={{ paddingBottom: "5px", display: explore_user ? "none" : "block" }}>
            <ExploreIcon src={iconSrc.add_user} style={{ height: "22px", width: "22px", marginRight: "0px"}} />
          </ExploreBarOvalButton>
          <ExploreBarOvalButton title="Create a new Group inside this one" onClick={handlers.onAddGroupClick} style={{ marginRight: "55px", paddingBottom: "5px", opacity: explore_user ? "0.0" : "1.0" }}> {/* TODO: */}
            <ExploreIcon src={iconSrc.add_group} style={{ height: "22px", width: "22px", marginRight: "0px"}} />
          </ExploreBarOvalButton>
        </>
      }
      {!explore_item &&
        <>
          <ExploreBarOvalButton title="Perform the Search" onClick={() => onSearchClick(searchTerm)}>
            <ExploreIcon src={iconSrc.search} style={{ height: "24px", width: "24px", marginBottom: "-5px"}} />
          </ExploreBarOvalButton>
          <ExploreBarOvalButton style={{ paddingBottom: "5px", opacity: "0" }}>
            <ExploreIcon src={iconSrc.add_user} style={{ height: "22px", width: "22px", marginRight: "0px"}} />
          </ExploreBarOvalButton>
          <ExploreBarOvalButton style={{ marginRight: "55px", paddingBottom: "5px", opacity: "0" }}>
            <ExploreIcon src={iconSrc.plus} style={{ height: "22px", width: "22px", marginRight: "0px"}} />
          </ExploreBarOvalButton>
        </>
      }
    </ExploreBarWrapper>
  );
}

const ExploreTableBody = ({
  // refr, results, scope, scopeId, adding, loaded // scope options: null, "", "group", "user"
  refr, results, scope, scopeId, adding, loaded, handlers // scope options: null, "", "group", "user"
}) => {
  // console.log('~~~~~ Explore(), ExploreTableBody() - results.group_array.length = ' + results.group_array.length);
  // console.log('~~~~~ Explore(), ExploreTableBody() - results.user_array.length = ' + results.user_array.length);
  // console.log('~~~~~ Explore(), ExploreTableBody() - results.workout_array.length = ' + results.workout_array.length);
  const [selectedRowId, setSelectedRowId] = useState(''); 
  const [dimmedRowId, setDimmedRowId] = useState('');
  // const noGroupResults = (results && results.group_array && results.group_array.length === 0) ? true : false; // empty array returned - show message
  // const noUserResults = (results && results.user_array && results.user_array.length === 0) ? true : false; // empty array returned - show message
  // const noWorkoutResults = (results && results.workout_array && results.workout_array.length === 0) ? true : false; // empty array returned - show message
  
  let showGroups = false;
  let showUsers = false;
  let showWorkouts = false;
  let showExercises = false;
  let isGroupExplore = scope === 'group';
  let isUserExplore = scope === 'user';
  let isWorkoutExplore = scope === 'workout';
  let isExerciseExplore = scope === 'exercise';
  let isMovementExplore = scope === 'movement';
  let showAssignedBy = (isGroupExplore || isUserExplore || isWorkoutExplore) && !adding; // use for "Assigned by" text in workoutRows
  let showRemove = (isGroupExplore || isUserExplore || isWorkoutExplore || isExerciseExplore) && !adding;
  let showAdd = adding;
  let resultSections = 0;

  const group_id = results.group_id_guid ? results.group_id_guid : null;
  const user_id = results.user_id_guid ? results.user_id_guid : null;
  const workout_id = isWorkoutExplore && scopeId && scopeId.length > 20 ? scopeId : results.workout_id_guid ? results.workout_id_guid : null;
  const addingToType = group_id ? 'group' : user_id ? 'user' : workout_id ? 'workout' : 'unknown type';
  const addingToId = group_id ? group_id : user_id ? user_id : workout_id ? workout_id : 'unknown id_guid';

  console.log('~~~~~ Explore(), ExploreTableBody(), workout_id is *' + workout_id + '*, addingToType is *' + addingToType + '*, addingToId is *' + addingToId + '*');


  if(!results || results === undefined){
    results = {};
    // console.log('~~~~~ Explore(), ExploreTableBody() - results was undefined, set to {}');
  }

  if (!results.group_array || results.group_array === undefined){
    results.group_array = [];
    // console.log('~~~~~ Explore(), ExploreTableBody() - results.group_array was null or undefined, set to []');
  }

  if (!results.user_array || results.user_array === undefined){
    results.user_array = [];
    // console.log('~~~~~ Explore(), ExploreTableBody() - results.user_array was null or undefined, set to []');
  } 
   
  if (!results.workout_array || results.workout_array === undefined){
    results.workout_array = [];
    // console.log('~~~~~ Explore(), ExploreTableBody() - results.workout_array was null or undefined, set to []');
  }

  if (!results.exercise_array || results.exercise_array === undefined){
    results.exercise_array = [];
    // console.log('~~~~~ Explore(), ExploreTableBody() - results.exercise_array was null or undefined, set to []');
  }

  if (!results.movement_array || results.movement_array === undefined){
    results.movement_array = [];
    // console.log('~~~~~ Explore(), ExploreTableBody() - results.movement_array was null or undefined, set to []');
  }
  

  // if(results && results.group_array && results.group_array.length > 0){ 
  //   // console.log('~~~~~ Explore(), ExploreTableBody() - results && results.group_array && results.group_array.length > 0, showGroups = true');
  //   showGroups = true;
  // }

  // if(results && results.user_array && results.user_array.length > 0){
  //   // console.log('~~~~~ Explore(), ExploreTableBody() - results && results.user_array && results.user_array.length > 0, showUsers = true');
  //   showUsers = true;
  // }

  // if(results && results.workout_array && results.workout_array.length > 0){
  //   // console.log('~~~~~ Explore(), ExploreTableBody() - results && results.workout_array && results.workout_array.length > 0, showWorkouts = true');
  //   showWorkouts = true;
  // }

  // if(results && results.exercise_array && results.exercise_array.length > 0){
  //   // console.log('~~~~~ Explore(), ExploreTableBody() - results && results.exercise_array && results.exercise_array.length > 0, showExercises = true');
  //   showExercises = true;
  // }

  function handleRowClick(type, rowId, name, isStaff, scopeId, readiness, email_string){
    console.log('~~~~~ Explore(), ExploreTableBody() - handleRowClick(' + type + ', ' + rowId + ', ' + name + ')');
    console.log('~~~~~ Explore(), ExploreTableBody() - handleRowClick() email_string is ' + email_string);
    setSelectedRowId(rowId);
    handlers.onRowClick(refr, type, rowId, name, isStaff, scopeId, readiness, email_string);
  }

  function handleRowAddClick(type, rowId, name, addingToType, addingToId){ // 'group', rowData.id_guid, rowData.name_string, addingToType, addingToId
    console.log('~~~~~ Explore(), ExploreTableBody() - handleRowAddClick(' + type + ', ' + rowId + ', ' + name + ')');
    setDimmedRowId(rowId);
    handlers.onRowAddClick(type, rowId, name, addingToType, addingToId);
  }

  function handleRowRemoveClick(type, rowId, name, addingToType, addingToId){ // 'group', rowData.id_guid, rowData.name_string, addingToType, addingToId
    console.log('~~~~~ Explore(), ExploreTableBody() - handleRowRemoveClick(' + type + ', ' + rowId + ', ' + name + ')');
    setDimmedRowId(rowId);
    // alert('setting dimmedRowId for ' + type + ' ' + name + ', with rowId ' + rowId);
    // alert('is it dim yet? - dimmedRowId is now ' + dimmedRowId);
    // alert('how about now? - dimmedRowId is now ' + dimmedRowId);
    handlers.onRowRemoveClick(type, rowId, name, addingToType, addingToId);
  }

  function handleVideoClick(url, rowId){ // url
    console.log('~~~~~ Explore(), ExploreTableBody() - handleVideoClick(' + url + ')');
    setDimmedRowId(rowId);
    handlers.onVideoClick(url);
  }

  // handleRowRemoveClick('group', rowData.id_guid, rowData.name_string, addingToType, addingToId)


  // function renderSectionRows(type){  // wait and work on this later - the goal is to not attempt to render the array.map for zero-length arrays,
                                        // as well as to render a single row element with a message when an empty row was returned by the API

  //   if(type === 'group' && !showGroups){ // if showGroups is true, results is undefined, or there was no results_group array returned from the api, or there are no rows to display
  //     // render nothing? no result rows, and no 'no results for these filter settings and search string' message
  //   } else if(type === 'group' && showGroups && group
  // }
  //  style={{ zIndex: "100000", display: "flex", flexDirection: "column", textAlign: "center", justifyContent: "center" }}

  return (
    <tbody style={{ width: "100%" }}> 
      
      {results.group_array?.map(rowData => {
        resultSections += 1;
        let rowSelected = selectedRowId === rowData.id_guid;
        let rowDimmed = dimmedRowId === rowData.id_guid;
        let groupName = rowData.name_string && rowData.name_string.length >= 2 ? rowData.name_string : rowData.group_name_string
        const rowHidden = isUserExplore && (rowData.user_approval_boolean === false || rowData.group_approval_boolean === false);
        if (!rowHidden){
          return (  
            <ExploreTableRow selected={rowSelected} dimmed={rowDimmed} key={rowData.id_guid + "_" + uuidv4()} onClick={() => handleRowClick('group', rowData.id_guid, groupName)} title={"user_approval_boolean: " + rowData.user_approval_boolean + ", group_approval_boolean: " + rowData.group_approval_boolean + ", rowHidden: " + rowHidden}>
              {/* render a cell with an 'add-arrow' button in it, with onClick={handlers.onRowAddClick}, if this Explore config requires it */}
              {showAdd && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowAddClick('group', rowData.id_guid, groupName, addingToType, addingToId); }} id={'add_' + rowData.id_guid} style={{ borderBottom: "none", backgroundColor: "#FFFFFF"}} title="Add this Group over there"><ExploreIcon src={iconSrc.add_arrow} style={{ height: "22px", width: "22px", opacity: "1.0" }} /></ExploreTableCell>}
              {!showAdd && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF"}}></ExploreTableCell>}
              {/* otherwise, render an empty td the appropriate width */}
              {/* last_assessment_composite_score_float */}
              <ExploreTableCell><ExploreIcon src={iconSrc.group} /></ExploreTableCell>
              
              <ExploreTableCell style={{ textAlign: "left", paddingLeft: "5px" }}>{groupName}</ExploreTableCell>
              <ExploreTableCell colSpan="8" align="left" style={{ fontWeight: "400", overflow: "clip", textAlign: "left" }}>{rowData.group_path_string}</ExploreTableCell>
              
              {/* render a cell with a 'remove-x' button in it, with onClick={handlers.onRowRemoveClick}, if this Explore config requires it */}
              {showRemove && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowRemoveClick('group', rowData.id_guid, groupName, addingToType, addingToId) }} id={'remove_' + rowData.id_guid} style={{ textAlign: "right", borderBottom: "none", backgroundColor: "#FFFFFF" }} title="Remove this Group"><ExploreIcon src={iconSrc.cancel} style={{ height: "22px", width: "22px", opacity: "1.0" }} /></ExploreTableCell>}
              {!showRemove && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF" }}></ExploreTableCell>}
            </ExploreTableRow>
          )
        }
      })
      }

      {results.user_array?.map(rowData => {
        resultSections += 1;
        let rowSelected = selectedRowId === rowData.id_guid;
        let rowDimmed = dimmedRowId === rowData.id_guid;
        const hasLastName = rowData.last_name_string && rowData.last_name_string.length > 0;
        const hasFirstName = rowData.first_name_string && rowData.first_name_string.length > 0;
        // const hasLastName = false;
        // const hasFirstName = false;
        const hasEmail = rowData.email_string && rowData.email_string.length > 0;
        // console.log('~~~~~ Explore(), ExploreTableBody(), user_array.map() - rowData.email_string is ' + rowData.email_string + ', and hasEmail is ' + hasEmail);
        // const userName = hasLastName && hasFirstName ? rowData.first_name_string + " " + rowData.last_name_string :
        const userName = hasLastName && hasFirstName ? rowData.last_name_string + ", " + rowData.first_name_string :
        hasEmail ? rowData.email_string : "Somehow, no Name or Email...";
        // console.log('~~~~~ Explore(), ExploreTableBody(), user_array.map() - userName is ' + userName);

        const pendingUserAccept = rowData.group_approval_boolean && !rowData.user_approval_boolean;
        const pendingGroupAccept = !rowData.group_approval_boolean && rowData.user_approval_boolean;
        const isEnterprise = rowData.enterprise_boolean && rowData.enterprise_boolean === true ? true : false;
        const isStaff = rowData.staff_boolean;
        const userIcon = isStaff ? iconSrc.staff : iconSrc.user;
        
        // we will not display users in explore_group lists unless they have true for at least one of the user_approval or group_approval booleans,
        // UNLESS the Explore instance is of type '_group_add'
        const showAllUsers = refr.includes('_add') || refr.includes('explore_r') || refr.includes('explore_l');
        const notAuser = !rowData.user_approval_boolean && !rowData.group_approval_boolean; // null is falsey
        // const hideUser = !showAllUsers && !rowData.user_approval_boolean && !rowData.group_approval_boolean; // null is falsey
        const hideUser = (rowData.user_approval_boolean === false || rowData.group_approval_boolean === false) && !isEnterprise && !showAllUsers; // null is falsey
        
        // const pendingMsgStaff = pendingGroupAccept ? "groupAccept='true'" : ""; 
        // const userRowType = pendingUserAccept ? "user_pending" : "user_pending_group";
        // const userRowType = pendingUserAccept && isStaff ? "staff_pending_user" : pendingUserAccept && !isStaff ? "athlete_pending_user" : 
        //   pendingGroupAccept && isStaff ? "staff_pending_group" : pendingGroupAccept && !isStaff ? "athlete_pending_group" : 
        //   rowData.user_approval_boolean && rowData.group_approval_boolean ? "approved_user" : "not_a_user";

        const userRowType = pendingUserAccept ? "user_pending_user" : pendingGroupAccept ? "user_pending_group" : 
          rowData.user_approval_boolean && rowData.group_approval_boolean ? "user_approved" : "user_not";

        // "user_pending_user", "user_pending_group", "user_approved", "user_not"  

        const hoverText = rowData.email_string + ', name: ' + userName + ', group_approval_boolean: ' + rowData.group_approval_boolean + ', user_approval_boolean: ' + rowData.user_approval_boolean + ', isEnterprise: ' + isEnterprise + ', isStaff: ' + isStaff + ', userRowType: ' + userRowType;

        // "staff_pending_user", "athlete_pending_user", "staff_pending_group", "athlete_pending_group", "not_a_user", "approved_user"


        const lastScore = Math.floor(rowData.last_assessment_score_composite_float * 100);
        const lastScoreDelta = rowData.last_assessment_score_composite_delta_float;
        const lastScoreColor = rowData.last_assessment_color_string;

        const readinessScore = rowData.readiness_overall_integer;
        const readinessColor = rowData.readiness_overall_color_string;

        const sleepScore = rowData.readiness_sleep_integer;
        const sleepColor = rowData.readiness_sleep_color_string;

        const hydrationScore = rowData.readiness_hydration_integer;
        const hydrationColor = rowData.readiness_hydration_color_string;

        const stressScore = rowData.readiness_stress_integer;
        const stressColor = rowData.readiness_stress_color_string;

        const sorenessScore = rowData.readiness_soreness_integer;
        const sorenessColor = rowData.readiness_soreness_color_string;

        const injuryScore = rowData.readiness_injury_integer;
        const injuryColor = rowData.readiness_injury_color_string;

        const complianceScore = rowData.compliance_integer;
        const complianceColor = rowData.compliance_color_string;

        const diameter = "30px";
        const userIdTemp = rowData.id_guid ? rowData.id_guid : rowData.user_id_guid; // until Nathan puts id_guid back as an attribute of user in inverse_boolean results

        const readiness = {

          lastScore: lastScore,
          lastScoreDelta: lastScoreDelta,
          lastScoreColor: lastScoreColor,
          readinessScore: readinessScore,
          readinessColor: readinessColor,
          sleepScore: sleepScore,
          sleepColor: sleepColor,
          hydrationScore: hydrationScore,
          hydrationColor: hydrationColor,
          stressScore: stressScore,
          stressColor: stressColor,
          sorenessScore: sorenessScore,
          sorenessColor: sorenessColor,
          injuryScore: injuryScore,
          injuryColor: injuryColor,
          complianceScore: complianceScore,
          complianceColor: complianceColor
        }; 
        
        
        // console.log('~~~~~~~~~~~ Explore(), ExploreTableBody(), user_array.map() - lastScore is ' + lastScore + ', and lastScoreColor is ' + lastScoreColor + ', and lastScoreDelta is ' + lastScoreDelta);
          //                                                                                                                             TODO: which? groupId, group_id, results.group_id_guid, scopeId
        if (!hideUser){
          return (
            <ExploreTableRow selected={rowSelected} dimmed={rowDimmed} key={userIdTemp + "_" + uuidv4()} onClick={() => handleRowClick(userRowType, userIdTemp, userName, isStaff, scopeId, readiness, rowData.email_string )}>
              {/* render a cell with an 'add-arrow' button in it, with onClick={handlers.onRowAddClick}, if this Explore config requires it */}
              {showAdd && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowAddClick('user', userIdTemp, userName, addingToType, addingToId); }} id={'add_' + userIdTemp} style={{ borderBottom: "none"}} title="Add this User over there"><ExploreIcon src={iconSrc.add_arrow} style={{ height: "22px", width: "22px", opacity: "1.0", backgroundColor: "#FFFFFF" }} /></ExploreTableCell>}
              {!showAdd && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF" }}></ExploreTableCell>}
              
              <ExploreTableCell><ExploreIcon src={userIcon} /></ExploreTableCell>
    
              <ExploreTableCell style={{ textAlign: "left", paddingLeft: "5px" }} title={hoverText}>{userName}</ExploreTableCell>
              {/* check for the special case where this user is staff, and the rest of the row after the icon and name is blank */}
              {/* check for special case where a 'pending message will be written across multiple cells */}
              
              {(pendingGroupAccept || pendingUserAccept) &&
                <ExploreTableCell colSpan="8">
                  <ExploreTableRowMessage title={userRowType}>Pending {pendingGroupAccept ? "Group" : "User"} Acceptance</ExploreTableRowMessage>
                </ExploreTableCell>
              }
              {(rowData.group_approval_boolean && rowData.user_approval_boolean && isStaff) &&
                <ExploreTableCell colSpan="8">
                  <ExploreTableRowMessage title={userRowType}></ExploreTableRowMessage>
                </ExploreTableCell>
              }
              {(notAuser) &&
                <ExploreTableCell colSpan="8">
                  <ExploreTableRowMessage title={userRowType}>Pending both Group and User Acceptance</ExploreTableRowMessage>
                </ExploreTableCell>
              }
              {(rowData.group_approval_boolean && rowData.user_approval_boolean && !isStaff) &&
                <>
                  <ExploreTableCell title="Most recent Assessment Score">
                    <ExploreScoreCircle diameter={diameter} score={lastScore} delta={lastScoreDelta} color={lastScoreColor} />
                  </ExploreTableCell>
                  <ExploreTableCell title="Most recent self-reported readiness Score, 5 is the best">
                    <ExploreScoreCircle diameter={diameter} score={readinessScore} color={readinessColor} />
                  </ExploreTableCell>
                  <ExploreTableCell title="Most recent self-reported sleep Score, 5 is the best">
                    <ExploreScoreCircle diameter={diameter} score={sleepScore} color={sleepColor} />
                  </ExploreTableCell>
                  <ExploreTableCell title="Most recent self-reported hydration Score, 5 is the best" >
                    <ExploreScoreCircle diameter={diameter} score={hydrationScore} color={hydrationColor} />
                  </ExploreTableCell>
                  <ExploreTableCell title="Most recent self-reported stress Score, 5 is the worst" >
                    <ExploreScoreCircle diameter={diameter} score={stressScore} color={stressColor} />
                  </ExploreTableCell>
                  <ExploreTableCell title="Most recent self-reported soreness Score, 5 is the worst">
                    <ExploreScoreCircle diameter={diameter} score={sorenessScore} color={sorenessColor} />
                  </ExploreTableCell>
                  <ExploreTableCell title="Most recent self-reported injury Score, 5 is the worst">
                    <ExploreScoreCircle diameter={diameter} score={injuryScore} color={injuryColor} />
                  </ExploreTableCell>
                  <ExploreTableCell title="Tabulated self-reported workout compliance Score, 5 is the best">
                    <ExploreScoreCircle diameter={diameter} score={complianceScore} color={complianceColor} />
                  </ExploreTableCell>
                </>
              }
    
              {/* render a cell with a 'remove-x' button in it, with onClick={handlers.onRowRemoveClick}, if this Explore config requires it */}
              {showRemove && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowRemoveClick('user', userIdTemp, userName, addingToType, addingToId )}} id={'remove_' + userIdTemp} style={{ textAlign: "right", borderBottom: "none", backgroundColor: "#FFFFFF" }} title="Remove this User"><ExploreIcon src={iconSrc.cancel} style={{ height: "22px", width: "22px", opacity: "1.0" }} /></ExploreTableCell>}
              {!showRemove && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF" }}></ExploreTableCell>}
            </ExploreTableRow>
          )
        }
        
      })}
    
      {results.workout_array?.map(rowData => {
        resultSections += 1;
        let rowSelected = selectedRowId === rowData.id_guid;
        let rowDimmed = dimmedRowId === rowData.id_guid;

        const myId = localStorage.getItem('loggedInUser');
        const mine = rowData.workout_user_id_guid === myId;
        // const notWorkoutFlow = true;

        // FAKE!!!! TODO: GET REAL DATA RETURNED FROM SERVER
        
        // rowData.owner_name_string = "Coach Bob";

        /*
        lock_dark: lock_dark_src,
        unlock_dark: unlock_dark_src,
        global_dark: global_dark_src,
        */
  
        const owner_user_name = rowData.workout_user_name_string && rowData.workout_user_name_string.length > 0 ? rowData.workout_user_name_string : null;
        const owner_user_first_name = rowData.workout_user_first_name_string && rowData.workout_user_first_name_string.length > 0 ? rowData.workout_user_first_name_string : null;
        console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - owner_user_first_name: ' + owner_user_first_name);
        const owner_user_last_name = rowData.workout_user_last_name_string && rowData.workout_user_last_name_string.length > 0 ? rowData.workout_user_last_name_string : null;
        console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - owner_user_last_name: ' + owner_user_last_name);
        const owner_user_first_and_last = owner_user_first_name && owner_user_last_name ? owner_user_first_name + ' ' + owner_user_last_name : null;
        console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - owner_user_first_and_last: ' + owner_user_first_and_last);
        const owner_name_string = owner_user_name ? owner_user_name : owner_user_first_and_last ? owner_user_first_and_last : null;
        console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - owner_name_string ' + owner_name_string);
        const owner_user_email = rowData.workout_user_email_string && rowData.workout_user_email_string.length > 0 ? rowData.workout_user_email_string : null;
        rowData.owner_name_string = owner_name_string ? owner_name_string : owner_user_email ? owner_user_email : "unknown";
        

        if (rowData.tag_string === undefined || rowData.tag_string === null || rowData.tag_string.length === 0){
          rowData.tag_string = "no tags yet";
        }

        const status_icon = rowData.global_boolean === true ? iconSrc.global_dark : 
        rowData.public_boolean === true ? iconSrc.group :
        rowData.published_boolean === true ? iconSrc.lock_dark : iconSrc.unlock_dark 

        const status_title = rowData.global_boolean === true ? "This workout is Global, and can be assigned" :
        rowData.public_boolean === true ? "This workout is Public, and can be assigned" :
        rowData.published_boolean === true ? "This workout is published, and can be assigned" : // TODO: add locked, unlocked and world to iconSrc definition
        "This workout is unpublished, and cannot be assigned";

        const assigning_group = rowData.group_name_string && rowData.group_name_string.length > 0 ? rowData.group_name_string : null;
        
        //pre-10-7-24 Nathan attribute name changes
        /*
        const assigning_user_name = rowData.workout_user_name_string && rowData.workout_user_name_string.length > 0 ? rowData.workout_user_name_string : null;
        const assigning_user_first_name = rowData.workout_user_first_name_string && rowData.workout_user_first_name_string.length > 0 ? rowData.workout_user_first_name_string : null;
        // console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - assigning_user_first_name: ' + assigning_user_first_name);
        const assigning_user_last_name = rowData.workout_user_last_name_string && rowData.workout_user_last_name_string.length > 0 ? rowData.workout_user_last_name_string : null;
        // console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - assigning_user_last_name: ' + assigning_user_last_name);
        */

        //post-10-7-24 Nathan attribute name changes
        const assigning_user_name = rowData.workout_user_user_name_string && rowData.workout_user_user_name_string.length > 0 ? rowData.workout_user_user_name_string : null;
        const assigning_user_first_name = rowData.workout_user_user_first_name_string && rowData.workout_user_user_first_name_string.length > 0 ? rowData.workout_user_user_first_name_string : null;
        // console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - assigning_user_first_name: ' + assigning_user_first_name);
        const assigning_user_last_name = rowData.workout_user_user_last_name_string && rowData.workout_user_user_last_name_string.length > 0 ? rowData.workout_user_user_last_name_string : null;
        // console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - assigning_user_last_name: ' + assigning_user_last_name);
        
        
        const assigning_user_first_and_last = assigning_user_first_name && assigning_user_last_name ? assigning_user_first_name + ' ' + assigning_user_last_name : null;
        // console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - assigning_user_first_and_last: ' + assigning_user_first_and_last);
        const assigned_by_name = assigning_group ? assigning_group : assigning_user_name ? assigning_user_name : assigning_user_first_and_last ? assigning_user_first_and_last : null;
        // console.log('~~~~~ Explore(), ExploreTableBody(), results.workout_array.map() - assigned_by_name ' + assigned_by_name);
        const assigning_user_email = rowData.workout_user_email_string && rowData.workout_user_email_string.length > 0 ? rowData.workout_user_email_string : null;
        const assigned_by_entity_string = assigned_by_name ? assigned_by_name : assigning_user_email ? assigning_user_email : "unknown";
        const assigned_by_string = showAssignedBy ? "Assigned by " + assigned_by_entity_string : "";
        return (
          <ExploreTableRow selected={rowSelected} dimmed={rowDimmed} key={rowData.id_guid + "_" + uuidv4()} onClick={() => handleRowClick('workout', rowData.id_guid, rowData.name_string)}>
            {/* render a cell with an 'add-arrow' button in it, with onClick={handlers.onRowAddClick}, if this Explore config requires it */}
            {showAdd && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowAddClick('workout', rowData.id_guid, rowData.name_string, addingToType, addingToId); }} id={'add_' + rowData.id_guid} style={{ borderBottom: "none", backgroundColor: "#FFFFFF" }} title="Add this Workout over there"><ExploreIcon src={iconSrc.add_arrow} style={{ height: "22px", width: "22px", opacity: "1.0" }} /></ExploreTableCell>}
            {!showAdd && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF" }}></ExploreTableCell>}
          
            <ExploreTableCell title={mine ? "This is a Workout you created, and you can edit it" : "This is a Public or Global Workout, that you can use, but not edit"}>
              <ExploreIcon src={iconSrc.workout} style={{ opacity: mine || !isWorkoutExplore ? "1.0" : "0.25" }} />
            </ExploreTableCell>
            <ExploreTableCell title={rowData.name_string} style={{ textAlign: "left", paddingLeft: "5px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: "200px" }}>{rowData.name_string}</ExploreTableCell>

            {/* If this is a user explore results list, render an 8-column cell with the Assigned by information for this workout */}
            {refr.includes('user') &&
              <ExploreTableCell colSpan="8"  style={{ textAlign: "left", paddingLeft: "5px" }}>
                {assigned_by_string}
              </ExploreTableCell>          
            }

            {refr.includes('workout_l') &&
              <>
                <ExploreTableCell colSpan="3"  style={{ textAlign: "left", paddingLeft: "5px", minWidth: "140px" }}>
                {rowData.owner_name_string}
                </ExploreTableCell>          
                <ExploreTableCell colSpan="4"  style={{ textAlign: "left", paddingLeft: "5px", minWidth: "140px" }}>
                {rowData.tag_string}
                </ExploreTableCell> 
                <ExploreTableCell colSpan="1"  style={{ textAlign: "center", paddingLeft: "0px" }}>
                <ExploreIcon src={status_icon} title={status_title} style={{ height: "22px", width: "22px", opacity: rowData.published_boolean === true ? "1.0" : "0.3" }} />
                </ExploreTableCell> 
              </>
            }

            {(!refr.includes('user') && !refr.includes('workout_l')) &&
              <ExploreTableCell colSpan="8"></ExploreTableCell>          
            }

            {/* render a cell with a 'remove-x' button in it, with onClick={handlers.onRowRemoveClick}, if this Explore config requires it */}
            {showRemove && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowRemoveClick('workout', rowData.id_guid, rowData.name_string, addingToType, addingToId)}} id={'remove_' + rowData.id_guid} style={{ textAlign: "right", borderBottom: "none", backgroundColor: "#FFFFFF" }} title="Remove this Workout"><ExploreIcon src={iconSrc.cancel} style={{ height: "22px", width: "22px", opacity: "1.0" }} /></ExploreTableCell>}
            {!showRemove && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF"}}></ExploreTableCell>}
          </ExploreTableRow>
        )
      })}

      {results.exercise_array?.map(rowData => {
        resultSections += 1;
        let rowSelected = selectedRowId === rowData.id_guid;
        let rowDimmed = dimmedRowId === rowData.id_guid;
        const myId = localStorage.getItem('loggedInUser');
        const mine = rowData.user_id_guid === myId; 
        return (
          <ExploreTableRow selected={rowSelected} dimmed={rowDimmed} key={rowData.id_guid + "_" + uuidv4()} onClick={() => handleRowClick('exercise', rowData.id_guid, rowData.name_string)}>
            {/* render a cell with an 'add-arrow' button in it, with onClick={handlers.onRowAddClick}, if this Explore config requires it */}
            {showAdd && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowAddClick('exercise', rowData.id_guid, rowData.name_string, addingToType, addingToId); }} id={'add_' + rowData.id_guid} style={{ borderBottom: "none", backgroundColor: "#FFFFFF"}} title="Add this Exercise over there"><ExploreIcon src={iconSrc.add_arrow} style={{ height: "22px", width: "22px", opacity: "1.0" }} /></ExploreTableCell>}
            {!showAdd && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF"}}></ExploreTableCell>}
            {/* otherwise, render an empty td the appropriate width */}
            {/* last_assessment_composite_score_float */}
            <ExploreTableCell title={mine ? "This is an Exercise you created, and you can edit it" : "This is a Public exercise, that you can use, but not edit"}>
              <ExploreIcon src={iconSrc.exercise} style={{ opacity: mine ? "1.0" : "0.25" }} />
            </ExploreTableCell>
            
            <ExploreTableCell style={{ textAlign: "left", paddingLeft: "5px" }}>{rowData.name_string}</ExploreTableCell>
            <ExploreTableCell colSpan="8" align="left" style={{ fontWeight: "400", overflow: "clip", textAlign: "left" }}></ExploreTableCell>
            
            {/* render a cell with a 'remove-x' button in it, with onClick={handlers.onRowRemoveClick}, if this Explore config requires it */}
            {showRemove && <ExploreTableCell onClick={(e) => { e.stopPropagation(); handleRowRemoveClick('exercise', rowData.id_guid, rowData.name_string, addingToType, addingToId) }} id={'remove_' + rowData.id_guid} style={{ textAlign: "right", borderBottom: "none", backgroundColor: "#FFFFFF" }} title="Remove this Exercise"><ExploreIcon src={iconSrc.cancel} style={{ height: "22px", width: "22px", opacity: "1.0" }} /></ExploreTableCell>}
            {!showRemove && <ExploreTableCell style={{ borderBottom: "none", backgroundColor: "#FFFFFF" }}></ExploreTableCell>}
          </ExploreTableRow>
        )})
      }

      {results.movement_array?.map(rowData => {
        resultSections += 1;
        let rowSelected = selectedRowId === rowData.id_guid;
        let rowDimmed = dimmedRowId === rowData.id_guid;
        const myId = localStorage.getItem('loggedInUser');
        const mine = rowData.user_id_guid === myId; 
        const display_name = rowData.name_string && rowData.name_string.length > 0 ? rowData.name_string :
          rowData.title_string && rowData.title_string.length > 0 ? rowData.title_string : "Movement Name not found :-(";
        const hasDescription = rowData.subtitle_string && rowData.subtitle_string.length > 0;
        const description = hasDescription ? rowData.subtitle_string : "Movement Description not found :-(";
        const hasTags = rowData.tag_list_string && rowData.tag_list_string.length > 0;
        const tags = hasTags ? rowData.tag_list_string : "Movement Tags not found :-(";
        const video_url = rowData.video_url_string;
        const thumbnail_url = rowData.thumbnail_url_string;
        const marginLeft = "0px";

        return (
          <ExploreTableMovementRow style={{ width: "100%", borderBottom: "!important 2px solid #888888" }} selected={rowSelected} dimmed={rowDimmed} key={rowData.id_guid + "_" + uuidv4()} onClick={() => handleRowClick('movement', rowData.id_guid, rowData.name_string)}>
            {/* NOTE that background above has no visible effect */}

            <ExploreTableMovementCell align="center" id={'add_' + rowData.id_guid} title="Add this Exercise over there">
              <ExploreIcon src={iconSrc.add_arrow} style={{ height: "22px", width: "22px", opacity: "1.0" }} />
            </ExploreTableMovementCell>

            <ExploreTableMovementCell></ExploreTableMovementCell>

            <ExploreTableMovementCell style={{ display: "inline-block", width: "600px", cursor: "pointer" }}>
              <div className="ExploreMovementCard" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className="ExploreMovementCardThumbnail" style={{ width: "30%", textAlign: "center", background: "#fff" }} title="A click here should play the video somewhere..." onClick={(e) => { e.stopPropagation(); handleVideoClick(video_url) }}>
                  {/* add something here with a play button overlay to launch the video to play somewhere... in a modal maybe? */}
                  <img src={thumbnail_url}  style={{ height: "100px" }} alt="movement thumbnail" />
                </div>
                <div className="ExploreMovementCardMetadata" style={{ width: "70%", display: "flex", flexDirection: "column", justifyContent: "space-between", padding: "5px" }} title="Add an Exercise to the Workout based on this movement">
                  <div className="ExploreMovementCardNameDiv" style={{ fontWeight: "bold" }}>
                    {display_name}
                  </div>
                  {hasDescription &&
                    <div className="ExploreMovementCardDescription" style={{ fontWeight: "normal", textAlign: "left" }}>
                      {description}
                    </div>
                  }
                  {hasTags &&
                    <div className="ExploreMovementCardTagDiv" style={{ fontWeight: "normal", textAlign: "left" }}>
                      Tags: {tags}
                    </div>
                  }
                </div>
              </div>
            </ExploreTableMovementCell>

            <ExploreTableMovementCell></ExploreTableMovementCell>
            
          </ExploreTableMovementRow>
        )})
      }

      {(resultSections === 0 && refr === '_l') && 
        <ExploreTableMovementRow style={{ width: "100%", borderBottom: "!important 2px solid #888888" }} key={"empty_results_" + uuidv4()}>
          <ExploreTableMovementCell align="center" colSpan="12" id="no_explore_results" style={{ textAlign: "center" }}>
            Perform a Search to populate this results list!
          </ExploreTableMovementCell>
        </ExploreTableMovementRow>
      }

    </tbody>
  )
    
}

const ExploreTable = ({
  refr, scope, scopeId, results, adding, loaded, handlers
}) => {
  // console.log('~~~~~ Explore(), ExploreTable() - adding: ' + adding);
  // console.log('~~~~~ Explore(), ExploreTable() - results: ' + results);
  // console.log('~~~~~ Explore(), ExploreTable() - scope: ' + scope);

  const noIcons = scope === "workout" || scope === "movement" || refr.includes('exercise');
  const movementList = scope === "movement";
  const workoutList = scope === "workout" && refr.includes('workout_l');
  const roundedBarEnds = movementList || workoutList;
  return (
    <ExploreTableWrapper >
      <ExploreTableBodyWrapper>
        <table style={{ display: "table", borderCollapse: "separate", borderSpacing: movementList ? "0 15px" : "0 2px", marginTop: "-5px", width: "100%" }}>
          <ExploreTableHeader>
            <tr>
              <ExploreHeaderCell width="6%" align="center" style={{ background: "none" }}><ExploreIcon src={iconSrc.add_arrow} style={{ height: "24px", width: "24px", opacity: "0.0" }} /></ExploreHeaderCell>
              <ExploreHeaderCell width="6%" align="center" style={{ borderRadius: "15px 0 0 15px" }}><ExploreIcon src={iconSrc.group} style={{ opacity: "0.0" }} /></ExploreHeaderCell>
              <ExploreHeaderCell width={movementList ? "30%" : "36%"} align="left" title={noIcons ? null : "For Users, email is shown if name was not provided"} style={{ paddingLeft: "30px", textAlign: "left", borderRadius: movementList ? "0 15px 15px 0" : "0" }}>
                <div className="MenuSortDirectionDiv" style={{ display: movementList ? "inline-block" : "none", width: "31%", textAlign: "right", paddingRight: "15px" }}>^</div>
                <div className="ColumnNameDiv" style={{ display: "inline-block" }}>Name</div>
              </ExploreHeaderCell>
              
              {workoutList &&
                <>
                  <ExploreHeaderCell width="36%" align="left" colSpan="3" title={noIcons ? null : "For Users, email is shown if name was not provided"} style={{ paddingLeft: "30px", textAlign: "left", borderRadius: movementList ? "0 15px 15px 0" : "0" }}>
                    <div className="ColumnNameDiv" style={{ display: "inline-block" }}>Owner</div>
                  </ExploreHeaderCell>     
                  <ExploreHeaderCell width="40%" align="left" colSpan="4" title={noIcons ? null : "For Users, email is shown if name was not provided"} style={{ paddingLeft: "30px", textAlign: "left", borderRadius: movementList ? "0 15px 15px 0" : "0" }}>
                    <div className="ColumnNameDiv" style={{ display: "inline-block" }}>Tags</div>
                  </ExploreHeaderCell> 
                  <ExploreHeaderCell align="left" style={{ paddingLeft: "30px", textAlign: "left", borderRadius: "0 15px 15px 0" }}>
                    <div className="ColumnNameDiv" style={{ display: "inline-block" }}></div>
                  </ExploreHeaderCell>               
                </>
              }
              
              {(!movementList && !workoutList) &&
                <>
                  <ExploreHeaderCell width="6%" align="center" title={noIcons ? null : "Assessment - most recent score"}><ExploreIcon src={iconSrc.assessment} style={{ opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                  <ExploreHeaderCell width="6%" align="center" title={noIcons ? null : "Estimated Readiness"}><ExploreIcon src={iconSrc.readiness} style={{ opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                  <ExploreHeaderCell width="6%" style={{ textAlign: "left" }} title={noIcons ? null : "Sleep Quality"}><ExploreIcon src={iconSrc.sleep} style={{ opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                  <ExploreHeaderCell width="6%" style={{ textAlign: "left" }} title={noIcons ? null : "Hydration Level"}><ExploreIcon src={iconSrc.hydration} style={{ opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                  <ExploreHeaderCell width="6%" style={{ textAlign: "left" }} title={noIcons ? null : "Stress Level"}><ExploreIcon src={iconSrc.stress} style={{ height: "34px", width: "34px", opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                  <ExploreHeaderCell width="6%" style={{ textAlign: "left" }} title={noIcons ? null : "Soreness"}><ExploreIcon src={iconSrc.soreness} style={{ height: "30px", width: "30px", marginLeft: "-10px", opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                  <ExploreHeaderCell width="6%" align="center" style={{ paddingRight: "9px" }} title={noIcons ? null : "Injury"}><ExploreIcon src={iconSrc.injury} style={{ height: "21px", width: "21px", opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                  <ExploreHeaderCell width="6%" style={{ borderRadius: "0 15px 15px 0", textAlign: "left" }} title={noIcons ? null : "Workout Compliance"}><ExploreIcon src={iconSrc.compliance} style={{ opacity: noIcons ? "0.0" : "1.0" }} /></ExploreHeaderCell>
                </>
              }
              <ExploreHeaderCell width="6%" align="center" style={{ background: "none" }}><ExploreIcon src={iconSrc.cancel} style={{ height: "24px", width: "24px", opacity: "0.0" }} /></ExploreHeaderCell>
            </tr>
          </ExploreTableHeader>

          <ExploreTableBody 
            refr={refr}
            scope={scope} 
            scopeId={scopeId}
            results={results} 
            adding={adding}
            loaded={loaded}
            handlers={handlers}
          />
        </table>
      </ExploreTableBodyWrapper>
    </ExploreTableWrapper >

  );
}


const Explore = ({
  refr, scopeId, results, handlers
}) => {
  const [loaded, setLoaded] = useState(false);
  const [scope, setScope] = useState(refr.includes('group') ? 'group' : refr.includes('user') ? 'user' : refr.includes('workout') ? 'workout' : refr.includes('exercise') ? 'exercise' : refr.includes('movement') ? 'movement' : null); // will be group|user|workout|exercise|movement|null
  const adding = refr.includes('add');
  const results_likeString_bool = results && results.like_string;
  // const results_relevant_bool = results && results.group_id_guid && results.group_id_guid === scopeId; // TODO: remove this line
  const results_relevant_bool = (results && results.group_id_guid && results.group_id_guid === scopeId) || refr.includes('workout') || refr.includes('exercise') || refr.includes('movement');
  // const results_relevant_bool = true; // TODO: remove this line
  const valid_likeString_bool = results_relevant_bool && results_likeString_bool;
  const [likeString, setLikeString] = useState(valid_likeString_bool ? results.like_string : ''); // TODO: hold like_string in state here, so automatic useEffect search can use most recent value
  // console.log('~~~~~~~~~~ Explore(), - refr is ' + refr + ', and scopeId is ' + scopeId);

  function handleSearch(refr, like_string, group_id, user_id, workout_id, group_boolean, user_boolean, workout_boolean, inverse_boolean){
    setLikeString(like_string);
    handlers.onSearch(refr, like_string, group_id, user_id, workout_id, group_boolean, user_boolean, workout_boolean, inverse_boolean);
    setLoaded(false);
  }

  
  useEffect(() => { 
    console.log('~~~~~ Explore(), useEffect() - refr is *' + refr + '*, and scopeId is *' + scopeId + '* and results.like_string is *' + results.like_string + '*');
    if (scopeId && scopeId.length > 20){  // for the case where a scopeId was provided
      // refr, like_string, group_id, user_id, workout_id, group_boolean, user_boolean, workout_boolean, inverse_boolean
      // handleSearch(refr, likeString, refr.includes('group') ? scopeId : null, refr.includes('user') ? scopeId : null, true, true, true, adding);
      console.log('~~~~~ Explore(), useEffect(), inside scopeId was provided conditional, scopeId is *' + scopeId + '*');
      handleSearch(
        refr,                                   
        likeString, 
        refr.includes('group') ? scopeId : null,    // group_id
        refr.includes('user') ? scopeId : null,     // user_id
        refr.includes('workout') ? scopeId : null,  // workout_id
        true,                                       // group_boolean    - always return groups
        true,                                       // user_boolean     - always return users
        refr.includes('workout') ? false : true,    // workout_boolean  - only return workoouts if refr DOES NOT include 'workout'?
        adding                                      // inverse_boolean
      );
    } else if (scope === "workout" || scope === "exercise" || scope === "movement"){
      console.log('~~~~~ Explore(), useEffect(), inside else if - refr is *' + refr + '*, and likeString is *' + likeString + '*');
      handleSearch(refr, likeString, null, null, null, false, false, false, false); // TODO: change second to last false to a ternary for workouts?
    } /* else if (refr.includes('movement') && results && results.like_string){
      // let currentLikeString = results && results.like_string ? results.like_string : '';
      console.log('~~~~~ Explore(), useEffect(), inside else if #2 - refr is *' + refr + '*, and results.like_string is *' + results.like_string + '*');
      handleSearch(refr, results.like_string, null, null, null, false, false, true, false);
    }
    */ // TODO: remove this commented-out else-if code

  }, [adding, likeString, refr, scope, scopeId]); // handlers, 
  // }, [adding, likeString, onSearch, refr, scope, scopeId]); // TODO: why did this re-launch search forever when Explore_l and User/User_explore_r both open??

  useEffect(() => {
    // alert('Search results have updated');
    setTimeout(() => {
      setLoaded(true);
    }, 1250);
    
  }, [results]);


  return (

    <>
      {loaded ?  
        <ExploreWrapper style={{ position: "relative", width: "95%" }}>
          {console.log('~~~~~ Explore() - in return() for render!')}
          <ExploreBar 
            refr={refr}
            adding={adding} 
            scope={scope}
            scopeId={scopeId}
            likeString={likeString}
            handlers={handlers}
            handleSearch={handleSearch}
          />
          <ExploreTable      
            refr={refr}
            results={results}
            scope={scope} 
            scopeId={scopeId}
            adding={adding}
            loaded={loaded}
            handlers={handlers}
            // style={{ borderCollapse: "separate", borderSpacing: "0 10px", marginTop: "-10px" }}
          />
        </ExploreWrapper>
      : <LoadingDivLayer  style={{ position: "relative", width: "100%", height: "100%" }}/>}

    </>

  );

}

export default Explore;