// src/constants/themes_and_colors.js

import { storageAvailable } from '../helpers';

const localStorageExists = storageAvailable("localStorage");
/*
const StretchLab_red = "#ee4b54";
const StretchLab_blue = "#003348";
const StretchLab_green = "#00acba";

const StretchLab_logo_src = "sl-logo-full-color.png";
const StretchLab_loading_src = "SL_loading.svg";
*/
const Physmodo_purple = "#516efe";
const Physmodo_blue = "#3c9bff";

let Physmodo_accent_color = Physmodo_purple;
let Physmodo_logo_src = "Physmodo_logo.svg";
let northsideLogoSrc = "northside_logo_gold.jpg";
let massageAddictLogoSrc = "massage_addict_logo.png";
let Physmodo_loading_src = "Physmodo_loading_1.svg";
let trashcanSrc = "trashCanGrey_24.png"; // "trashCanGrey_48.png" - these are #909090
let trashcanSrc_active = "trashCanBlack_24.png"; // "trashCanDarkGrey_48.png" - greys are #555555, blacks are #000000
let overviewSrc = "tab_svg_icons/Overview.svg"; // - these are #696969
let overviewSrc_active = "tab_svg_icons/Overview_active.svg"; // - these are #FFFFFF
let athletesSrc = "tab_svg_icons/Members.svg"; // - these are #696969
let athletesSrc_active = "tab_svg_icons/Members_active.svg"; // - these are #FFFFFF
let groupsSrc = "tab_svg_icons/Groups.svg"; // - these are #696969
let groupsSrc_active = "tab_svg_icons/Groups_active.svg"; // - these are #FFFFFF
let programsSrc = "tab_svg_icons/Programs.svg"; // - these are #696969
let programsSrc_active = "tab_svg_icons/Programs_active.svg"; // - these are #FFFFFF
let exercisesSrc = "tab_svg_icons/Exercises.svg"; // - these are #696969
let exercisesSrc_active = "tab_svg_icons/Exercises_active.svg"; // - these are #FFFFFF
let movementsSrc = "tab_svg_icons/Movements.svg"; // - these are #696969
let movementsSrc_active = "tab_svg_icons/Movements_active.svg"; // - these are #FFFFFF
let addUserSrc = "addUser_64.png"; // this is white #FFFFFF to go in Invite Athlete button
let checkedSrc = "checkmark_icon.svg"; // - these are #696969
let checkedSrc_active = "checkmark_icon_active.svg"; // - these are #FFFFFF
let search_icon_src = "Shell_assets/search1.svg";
let remove_x_icon_src = "Shell_assets/remove.svg";
let cancel_icon_src = "Shell_assets/cancel.png";
let add_arrow_icon_src = "Shell_assets/add_arrow.png";
let logout_icon_src = "logout.svg";
let logout_white_icon_src = "Shell_assets/logout_white.png";
let plus_icon_src = "Shell_assets/plus.png";
let user_icon_src = "Shell_assets/user.png";
let staff_icon_src = "Shell_assets/staff.png";
let add_user_icon_src = "Shell_assets/add_user.png";
let add_group_icon_src = "Shell_assets/add_group.png";
let sort_icon_src = "Shell_assets/sort.png";
let group_icon_src = "Shell_assets/group.png";
let assessment_icon_src = "Shell_assets/assessment.png";
let readiness_icon_src = "Shell_assets/readiness.png";
let sleep_icon_src = "Shell_assets/sleep.png";
let hydration_icon_src = "Shell_assets/hydration.png";
let stress_icon_src = "Shell_assets/stress.png";
let soreness_icon_src = "Shell_assets/soreness.png";
let injury_icon_src = "Shell_assets/injury.png";
let compliance_icon_src = "Shell_assets/workout_lunge_right.png";
let exercise_icon_src = "Shell_assets/exercise.png";
let workout_icon_src = "Shell_assets/workout.png";
let workout_light_icon_src = "Shell_assets/workout.png";
let user_light_icon_src = "Shell_assets/user_light.png";
let group_light_icon_src = "Shell_assets/group_light.png";
let checkmark_icon_src = "Shell_assets/checkmark-80.png";
let play_icon_src = "Shell_assets/play-67.png";
let assessment_white_icon_src = "Shell_assets/assessment_white.png";
let readiness_white_icon_src = "Shell_assets/readiness_white.png";
let rocket_white_icon_src = "Shell_assets/icons8-rocket-white-100.png";
let rocket_green_icon_src = "Shell_assets/icons8-rocket-green-100.png";
let rocket_cccccc_icon_src = "Shell_assets/icons8-rocket-cccccc-100.png";
let rocket_white_filled_icon_src = "Shell_assets/icons8-rocket-white-filled-100.png";
let overview_white_icon_src = "Shell_assets/icons8-overview-white-100.png";
let overview_white_filled_icon_src = "Shell_assets/icons8-overview-white-filled-100.png";
let gym_white_icon_src = "Shell_assets/icons8-gym-white-100.png";
let gym_white_filled_icon_src = "Shell_assets/icons8-gym-white-filled-100.png";
let stretching_white_icon_src = "Shell_assets/icons8-stretching-white-100.png";
let stretching_white_filled_icon_src = "Shell_assets/icons8-stretching-white-filled-100.png";
let running_white_icon_src = "Shell_assets/icons8-exercise-white-100.png";
let running_white_filled_icon_src = "Shell_assets/icons8-exercise-white-filled-100.png";
let schedule_weight_white_icon_src = "Shell_assets/icons8-schedule-weight-white-100.png";
let schedule_weight_white_filled_icon_src = "Shell_assets/icons8-schedule-weight-white-filled-100.png";
let workouts_header_black_icon_src = "Shell_assets/workouts_header_icon_black.png";
let runner_black_filled_icon_src = "Shell_assets/icons8-exercise-black-100.png";
let rocket_black_icon_src = "Shell_assets/icons8-rocket-black-100.png";
let circle_black_check_green_fill_icon_src = "Shell_assets/icons8-checkmark-black-in-green-fill-100.png";
let circle_black_icon_src = "Shell_assets/icons8-circle-nocheckmark-black-100.png";
let chevron_left_black_fill_icon_src = "Shell_assets/icons8-chevron-left-black-100.png";
let squat_black_icon_src = "Shell_assets/icons8-squats-100.png";
let fizzy_squat_black_icon_src = "Shell_assets/overhead-squat-100.png";
let copy_black_icon_src = "Shell_assets/icons8-copy-100.png";
let dropdown_arrow_src = "Shell_assets/icons8-drop-down-30.png";

let lock_dark_src = "Shell_assets/lock_dark.svg";
let lock_light_src = "Shell_assets/lock_light.svg";
let unlock_dark_src = "Shell_assets/unlock_dark.svg";
let unlock_light_src = "Shell_assets/unlock_light.svg";
let global_dark_src = "Shell_assets/global_dark.svg";
let global_light_src = "Shell_assets/global_light.svg";

const iconSrc = {
  lock_dark: lock_dark_src,
  lock_light: lock_light_src,
  unlock_dark: unlock_dark_src,
  unlock_light: unlock_light_src,
  global_dark: global_dark_src,
  global_light: global_light_src,
  play: play_icon_src,
  checkmark: checkmark_icon_src,
  plus: plus_icon_src,
  sort: sort_icon_src,
  remove_x: remove_x_icon_src,
  cancel: cancel_icon_src,
  add_arrow: add_arrow_icon_src,
  search: search_icon_src,
  logout: logout_icon_src,
  logout_white: logout_white_icon_src,
  user: user_icon_src,
  staff: staff_icon_src,
  add_user: add_user_icon_src,
  add_group: add_group_icon_src,
  group: group_icon_src,
  group_light: group_light_icon_src,
  user_light: user_light_icon_src,
  workout_light: workout_light_icon_src,
  assessment: assessment_icon_src,
  assessment_white: assessment_white_icon_src,
  readiness: readiness_icon_src,
  readiness_white: readiness_white_icon_src,
  sleep: sleep_icon_src,
  hydration: hydration_icon_src,
  stress: stress_icon_src,
  soreness: soreness_icon_src,
  injury: injury_icon_src,
  compliance: compliance_icon_src,
  exercise: exercise_icon_src,
  workout: workout_icon_src,
  remove: trashcanSrc, // "trashCanGrey_48.png" - these are #909090
  remove_active: trashcanSrc_active,
  athletes: athletesSrc,
  athletes_active: athletesSrc_active,
  groups: groupsSrc,
  groups_active: groupsSrc_active,
  programs: programsSrc,
  programs_active: programsSrc_active,
  exercises: exercisesSrc,
  exercises_active: exercisesSrc_active,
  movements: movementsSrc,
  movements_active: movementsSrc_active,
  addUser_active: addUserSrc,
  checked: checkedSrc,
  checked_active: checkedSrc_active,
  rocket_white: rocket_white_icon_src,
  rocket_green: rocket_green_icon_src,
  rocket_cccccc: rocket_cccccc_icon_src,
  rocket_white_filled: rocket_white_filled_icon_src,
  overview_white: overview_white_icon_src,
  overview_white_filled: overview_white_filled_icon_src,
  gym_white: gym_white_icon_src,
  gym_white_filled: gym_white_filled_icon_src,
  stretching_white: stretching_white_icon_src,
  stretching_white_filled: stretching_white_filled_icon_src,
  running_white: running_white_icon_src,
  running_white_filled: running_white_filled_icon_src,
  schedule_weight_white: schedule_weight_white_icon_src,
  schedule_weight_white_filled: schedule_weight_white_filled_icon_src,
  workouts_header_black: workouts_header_black_icon_src,
  runner_black_filled: runner_black_filled_icon_src,
  rocket_black: rocket_black_icon_src,
  compliance_checked: circle_black_check_green_fill_icon_src,
  compliance_unchecked: circle_black_icon_src,
  chevron_left_black_filled: chevron_left_black_fill_icon_src,
  squat_black: squat_black_icon_src,
  fizzy_squat_black: fizzy_squat_black_icon_src,
  copy_black: copy_black_icon_src,
  dropdown_arrow: dropdown_arrow_src
}

if (localStorageExists && localStorage.getItem('theme') === "alternate") {
  Physmodo_accent_color = Physmodo_blue;
  Physmodo_logo_src = "Physmodo_logo_2.png";
  Physmodo_loading_src = "Physmodo_loading_2.svg";
}

const accentColor = Physmodo_accent_color;
const logoSrc = Physmodo_logo_src;
const loadingSrc = Physmodo_loading_src;

const lightGrey = "#e9e9e9";

const colors = {
  background: "#ffffff",
  sidebarBg: "#eeeeee",
  lightGrey: "#e9e9e9",
  lightGreyDividers: lightGrey,
  blackText: "#000000",
  greyText: "#7a7c8d", // TODO: this is not really a grey shade...
  greyTitleText: "#888888",
  lightGreyText: "#b4b4b4",
  darkGreyText: "#555555",
  scoreGreen: "#7cea62",
  scoreYellow: "#f4f436",
  scoreRed: "#f2a2a",
  accent: accentColor,
  exploreCircleGray: '#cccccc',
  exploreCircleGreen: '#03ff00',
  exploreCircleYellow: '#ffff00',
  exploreCircleRed: '#FF0000',
  exploreBlueBtn: '#5b79fd'
}

export {
  accentColor,
  logoSrc,
  northsideLogoSrc,
  massageAddictLogoSrc,
  iconSrc,
  loadingSrc,
  trashcanSrc,
  trashcanSrc_active,
  overviewSrc,
  overviewSrc_active,
  athletesSrc,
  athletesSrc_active,
  groupsSrc,
  groupsSrc_active,
  programsSrc,
  programsSrc_active,
  exercisesSrc,
  exercisesSrc_active,
  movementsSrc,
  movementsSrc_active,
  addUserSrc,
  colors
}

