// helpers/index.js

import storageAvailable from './storageAvailable';
import betterFetch from './betterFetch';
import anotherHelper from './anotherHelper';

function capitalize(string) {
  console.log('~~~~~ helpers/index.js, capitalize() - string to capitalize is ' + string);

  return string.charAt(0).toUpperCase() + string.slice(1).substring(0, string.length - 1);
}

export {
  storageAvailable,
  betterFetch,
  anotherHelper,
  capitalize
}